import axios from 'axios';
import {
    CategoryContentResponse,
    ContentType,
    HomeResponse,
    LicenseType,
    SubscriptionResponse,
    SubscriptionType
} from '../../../interface/home';
import {
    HtiResponse,
    ListAllContentResponse,
    AllCategoryResponse
} from '../../../interface/request';
import { generatePath, generateCreatorPath, generateCategoryApiPath } from '../utilities';
import { ContentDetail } from '../../../interface/detailPage';
import { ListAllContentLastEvaluatedKey } from '../../../interface/lastEvaluatedKey';
import { RentalStatus } from './storeModel';
import { checkLastEvaluatedKey } from '../../Utilities/validateText';
import queryString from 'query-string';

export const getStoreHome = async () => {
    const path = `/store/homepage`;
    const {
        data: { data }
    } = await axios.get<HtiResponse<HomeResponse>>(path);
    return data;
};

export const getPublicHome = async (clientPrefix: string) => {
    const path = `/public/${clientPrefix}/homepage`;
    const {
        data: { data }
    } = await axios.get<HtiResponse<HomeResponse>>(path);
    return data;
};

export const getFreeHome = async (clientPrefix: string, isLogin = true) => {
    const path = isLogin ? `/store/free/homepage` : `/public/${clientPrefix}/free/homepage`;

    const {
        data: { data }
    } = await axios.get<HtiResponse<HomeResponse>>(path);
    return data;
};

export const getPrivateHome = async (
    prefix?: string,
    contentType?: ContentType,
    isPublic = true
) => {
    const path = generatePath('store', 'home', prefix, LicenseType.Private, contentType, isPublic);
    const {
        data: { data }
    } = await axios.get<HtiResponse<HomeResponse>>(path);
    return data;
};

export const getAllEbook = async (
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    contentType: ContentType,
    lastEvaluatedKey?: string | ListAllContentLastEvaluatedKey
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin ? `/store/free/contents` : `/public/${clientPrefix}/free/contents`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
        case LicenseType.Private: {
            const lastEvaluate = checkLastEvaluatedKey(lastEvaluatedKey);
            const path = generatePath(
                `store`,
                `listEbook`,
                clientPrefix,
                licenseType,
                undefined,
                !isLogin
            );
            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    contentType: contentType,
                    lastEvaluatedKey: lastEvaluate.lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
        default: {
            const path = isLogin ? `/store/contents` : `/public/${clientPrefix}/contents`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
    }
};

export const getSubcategoryContent = async <T = ListAllContentResponse>(
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    contentType: ContentType,
    categoryUID: string,
    subcategoryUID: string,
    lastEvaluatedKey?: string
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin
                ? `/store/free/categories/${categoryUID}/subcategories/${subcategoryUID}/contents`
                : `/public/${clientPrefix}/free/categories/${categoryUID}/subcategories/${subcategoryUID}/contents`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
        case LicenseType.Private: {
            const path = isLogin
                ? `/store/private/categories/${categoryUID}/${subcategoryUID}`
                : `/public/${clientPrefix}/store/private/categories/${categoryUID}/${subcategoryUID}`;

            const jsonString = JSON.stringify(lastEvaluatedKey);

            const lastEvaluate = lastEvaluatedKey ? btoa(jsonString) : undefined;

            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path, {
                params: {
                    contentType: contentType,
                    lastEvaluatedKey: lastEvaluate,
                    limit: 20
                }
            });
            return data;
        }
        default: {
            const path = isLogin
                ? `/store/categories/${categoryUID}/subcategories/${subcategoryUID}/contents`
                : `/public/${clientPrefix}/categories/${categoryUID}/subcategories/${subcategoryUID}/contents`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
    }
};

export const getWriterContent = async (
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    writerUID: string,
    creatorUID: string,
    lastEvaluatedKey?: string
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin
                ? `/store/free/writers/${writerUID}/contents`
                : `/public/${clientPrefix}/free/writers/${writerUID}/contents`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
        case LicenseType.Private: {
            const path = isLogin
                ? `/store/private/creators/${creatorUID}/authors/${writerUID}`
                : `/public/${clientPrefix}/store/private/creators/${creatorUID}/authors/${writerUID}`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
        default: {
            const path = isLogin
                ? `/store/writers/${writerUID}/contents`
                : `/public/${clientPrefix}/writers/${writerUID}/contents`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
    }
};

export const getAllCategory = async (
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    contentType: ContentType
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin
                ? `/categories/free/all`
                : `/public/${clientPrefix}/categories/free/all`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<AllCategoryResponse>>(path);
            return data;
        }
        case LicenseType.Private: {
            const path = generateCategoryApiPath(
                `categories`,
                undefined,
                clientPrefix,
                licenseType,
                contentType,
                !isLogin
            );
            const {
                data: { data }
            } = await axios.get<HtiResponse>(path);
            return data;
        }
        default: {
            const path = isLogin ? `/categories/all` : `/public/${clientPrefix}/categories/all`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<AllCategoryResponse>>(path);
            return data;
        }
    }
};

export const getCategoryContents = async <T = CategoryContentResponse>(
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    categoryUID: string,
    contentType: ContentType,
    limit?: number
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin
                ? `/store/free/categories/${categoryUID}/contents`
                : `/public/${clientPrefix}/free/categories/${categoryUID}/contents`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path, {
                params: {
                    limit: limit
                }
            });

            return data;
        }
        case LicenseType.Private: {
            const path = generateCategoryApiPath(
                `categories`,
                categoryUID,
                clientPrefix,
                licenseType,
                contentType,
                !isLogin
            );
            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path);
            return data;
        }
        default: {
            const path = isLogin
                ? `/store/categories/${categoryUID}/contents`
                : `/public/${clientPrefix}/categories/${categoryUID}/contents`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path, {
                params: {
                    limit: limit
                }
            });

            return data;
        }
    }
};

export const getPublisherContent = async (
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    publisherUID: string,
    contentType: ContentType,
    lastEvaluatedKey?: string
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin
                ? `/store/free/publishers/${publisherUID}/contents`
                : `/public/${clientPrefix}/free/publishers/${publisherUID}/contents`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
        case LicenseType.Private: {
            const path = generateCreatorPath(
                `store`,
                'creators',
                publisherUID,
                clientPrefix,
                licenseType,
                undefined,
                !isLogin
            );
            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    contentType: contentType,
                    limit: 60
                }
            });
            return data;
        }
        default: {
            const path = isLogin
                ? `/store/publishers/${publisherUID}/contents`
                : `/public/${clientPrefix}/publishers/${publisherUID}/contents`;
            const {
                data: { data }
            } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
                params: {
                    lastEvaluatedKey: lastEvaluatedKey,
                    limit: 60
                }
            });
            return data;
        }
    }
};

export const getRentableContents = async (
    isLogin: boolean,
    clientPrefix: string,
    lastEvaluatedKey?: string
) => {
    const path = isLogin ? `/store/rentable/contents` : `/public/${clientPrefix}/rentable/contents`;
    const {
        data: { data }
    } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
        params: { lastEvaluatedKey: lastEvaluatedKey }
    });
    return data;
};

export const getCustomContents = async (
    isLogin: boolean,
    clientPrefix: string,
    segmentUID: string,
    lastEvaluatedKey?: string
) => {
    const path = isLogin
        ? `/store/custom/${segmentUID}/contents`
        : `/public/${clientPrefix}/custom/${segmentUID}/contents`;
    const {
        data: { data }
    } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
        params: {
            lastEvaluatedKey: lastEvaluatedKey,
            limit: 60
        }
    });
    return data;
};

export const getSearchContent = async (
    isLogin: boolean,
    clientPrefix: string,
    licenseType: LicenseType,
    keyword: string,
    lastEvaluatedKey?: string
) => {
    const path = isLogin ? `/store/contents/search` : `/public/${clientPrefix}/contents/search`;

    const {
        data: { data }
    } = await axios.post<HtiResponse<ListAllContentResponse>>(path, {
        keyword: keyword,
        licenseType: licenseType,
        lastEvaluatedKey: lastEvaluatedKey,
        limit: 64
    });

    return data;
};

export const getContentDetail = async <T = ContentDetail>(
    isLogin: boolean,
    clientPrefix: string,
    contentUID: string,
    licenseType: LicenseType
) => {
    switch (licenseType) {
        case LicenseType.Free: {
            const path = isLogin
                ? `/store/free/contents/${contentUID}/detail`
                : `/public/${clientPrefix}/free/contents/${contentUID}/detail`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path);

            return data;
        }
        case LicenseType.Private: {
            const path = generatePath(
                `store`,
                `contents/${contentUID}`,
                clientPrefix,
                licenseType,
                undefined,
                !isLogin
            );
            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path);
            return data;
        }
        default: {
            const path = isLogin
                ? `/store/contents/${contentUID}`
                : `/public/${clientPrefix}/contents/${contentUID}`;

            const {
                data: { data }
            } = await axios.get<HtiResponse<T>>(path);

            return data;
        }
    }
};

export const getEbookList = (
    catId: string,
    subcatId: string,
    contentType: string,
    lastEvaluatedKey?: any,
    licenseType?: string
) => {
    const qString: any = checkLastEvaluatedKey(lastEvaluatedKey);
    qString['subcatId'] = subcatId;
    qString['contentType'] = contentType;
    qString['limit'] = 64;

    const path = generatePath(
        `/store`,
        `/categories/${catId}`,
        licenseType,
        LicenseType.Rent,
        ContentType.ebook
    );

    return axios.get(
        queryString.stringifyUrl({
            url: path,
            query: qString
        })
    );
};

export const rentEbook = (uid?: string, rentalStatus?: RentalStatus, licenseType?: string) => {
    if (rentalStatus === 'Rent') {
        const path = `/licenses`;
        const qString: any = {
            contentUID: uid,
            licenseType: licenseType ? licenseType : 'Rent'
        };
        return axios.post(path, qString);
    } else if (rentalStatus === 'WaitingList') {
        const path = `/licenses/${uid}/waiting`;
        const qString: any = {
            licenseType: licenseType ? licenseType : 'Rent'
        };
        return axios.post(path, qString);
    } else if (rentalStatus === 'WaitingListed') {
        const path = `/licenses/${uid}/waiting`;
        const qString: any = {
            licenseType: licenseType ? licenseType : 'Rent'
        };
        return axios.delete(path, qString);
    }
};

export const returnEbook = (
    uid?: string, //licenseUID
    rentalStatus?: string,
    licenseType?: string
) => {
    if (
        rentalStatus === 'Rented' ||
        (rentalStatus === undefined && licenseType) //bookshelf
    ) {
        const path = `/licenses/${uid}/return`;
        const qString: any = {
            licenseType: licenseType ? licenseType : 'Rent'
        };
        return axios.patch(path, qString);
    }
};

export const getListSets = async (
    isLogin: boolean,
    clientPrefix: string,
    subscriptionType: SubscriptionType
) => {
    const path = isLogin
        ? `/sets/${subscriptionType}/subscriptions`
        : `/public/${clientPrefix}/sets/${subscriptionType}/subscriptions`;
    const {
        data: { data }
    } = await axios.get<HtiResponse<SubscriptionResponse>>(path);
    return data;
};

export const getListContentSets = async (
    isLogin: boolean,
    clientPrefix: string,
    subscriptionUID: string,
    lastEvaluatedKey?: string
) => {
    const path = isLogin
        ? `/sets/${subscriptionUID}/contents`
        : `/public/${clientPrefix}/sets/${subscriptionUID}/contents`;

    const {
        data: { data }
    } = await axios.get<HtiResponse<ListAllContentResponse>>(path, {
        params: {
            lastEvaluatedKey: lastEvaluatedKey,
            limit: 64
        }
    });

    return data;
};

export function getContactList() {
    return axios.get(`/application/contactList`);
}

export function getIsPublicRegister(prefix: string) {
    return axios.get(`/public/${prefix}/theme`);
}
