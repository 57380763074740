import axios from 'axios';
import {
  OtpPassword,
  OtpRegister,
  SignInSsoBody,
  SignInSsoResponse,
  SsoUrl
} from '../../../interface/authen';
import {
  AddMemberStore,
  AddMemberStorePayload,
  getTokenByCodeResponse,
  HtiResponse,
  LoginResponse,
  PublicRegisterClientRes,
  RefactorTokenResponse,
  VerifyLoginOTPResponse
} from '../../../interface/request';
import { ClientLocation } from '../../../interface/client';
export const LOGIN_URL = `/signin/password`;
export const LOGOUT_URL = `/member/logout`;
export const REGISTER_URL = '/api/auth/register';
export const REQUEST_FORGOTPASSWORD_URL = '/signin/forgotPassword';
export const REQUEST_CHANGEPASSWORD_URL = '/member/password/otp';
export const VERIFY_OTP_URL = '/signin/verifyPasswordOTP';
export const RESET_PASSWORD_URL = '/signin/changePasswordOTP';
export const CHANGE_EMAIL_URL = '/member/email/requestotp';
export const REFACTOR_TOKEN_URL = '/signin/refactorToken';
export const REFRESH_TOKEN_URL = '/signin/refreshToken';
export const PUBLIC_REGISABLE_URL = '/signin/clients/new';
export const SIGNIN_SSO = '/signin/sso';
export const DELETE_CLIENT_URL = '/member/delete/client';

export interface LoginRequest {
  userID: string;
  password: string;
  notificationToken?: string;
  companyPrefix?: string;
}

export function login(request: LoginRequest) {
  return axios.post<HtiResponse<LoginResponse>>(LOGIN_URL, request);
}

export const getClientRegisteredInMember = async () => {
  try {
    const { data } = await axios.get<HtiResponse<RefactorTokenResponse>>(
      REFACTOR_TOKEN_URL
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPublicRegisterClient = async (
  memberUID?: string,
  latitude?: number,
  longitude?: number
) => {
  try {
    const { data } = await axios.get<HtiResponse<PublicRegisterClientRes>>(
      PUBLIC_REGISABLE_URL,
      {
        params: { memberUID, latitude, longitude }
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const AddMemberClientStore = async (payload: AddMemberStorePayload) => {
  try {
    const url = `/public/${payload.clientPrefix}/register/addStore`;
    const {
      data: { data }
    } = await axios.post<HtiResponse<AddMemberStore>>(url, {
      staffID: payload.staffID,
      memberUID: payload.memberUID,
      latitude: payload.latitude,
      longitude: payload.longitude
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAccount = async (password: string) => {
  const { data } = await axios.delete<HtiResponse>('/member/delete', {
    data: {
      password: password
    }
  });
  return data;
};

export const deleteLibrary = async (password: string, clientUID: string) => {
  const { data } = await axios.delete<HtiResponse>('/member/delete/client', {
    data: {
      password: password,
      clientUID: clientUID
    }
  });
  return data;
};

export const refreshNewToken = async (companyPrefix: string) => {
  try {
    const { data } = await axios.get<HtiResponse<LoginResponse>>(
      REFRESH_TOKEN_URL,
      {
        params: { companyPrefix }
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetPassword = async (
  newPassword: string,
  uid: string,
  otp: string,
  refCode: string
) => {
  const {
    data: { status }
  } = await axios.patch<HtiResponse>(RESET_PASSWORD_URL, {
    password: newPassword,
    verification: { uid: uid, referenceCode: refCode, otp: otp }
  });
  return status;
};

export const verifyRegister = async (
  prefix?: string,
  userID?: string,
  geoLocation?: ClientLocation
) => {
  const path = `${`/public/${prefix}/register/request`}`;
  let payload = {
    userID: userID
  };
  if (geoLocation) payload = await Object.assign(payload, { geoLocation });
  const {
    data: { data }
  } = await axios.post<HtiResponse<OtpRegister>>(path, payload);
  return data;
};
export const registerForm = async (
  prefix?: string,
  userID?: string,
  name?: string,
  password?: string,
  surname?: string,
  otp?: string,
  staffID?: string,
  latitude?: number,
  logitude?: number,
  referenceCode?: string
) => {
  const path = `${`/public/${prefix}/register`}`;

  const {
    data: { data }
  } = await axios.post<HtiResponse<LoginResponse>>(path, {
    userID: userID,
    name: name,
    password: password,
    surname: surname,
    otp: otp,
    staffID: staffID,
    latitude: latitude,
    logitude: logitude,
    referenceCode: referenceCode
  });
  return data;
};

export const verifyOTPregister = async (
  prefix?: string,
  userID?: string,
  refCode?: string,
  otp?: string
) => {
  const path = `${`/public/${prefix}/register/verify`}`;
  const {
    data: { data }
  } = await axios.post<HtiResponse<VerifyLoginOTPResponse>>(path, {
    userID: userID,
    referenceCode: refCode,
    otp: otp
  });
  return data;
};

export const verifyOTP = async (uid: string, otp: string, refCode: string) => {
  const {
    data: { data }
  } = await axios.post<HtiResponse<LoginResponse>>(VERIFY_OTP_URL, {
    uid: uid,
    referenceCode: refCode,
    otp: otp
  });
  return data;
};
export const verifyOTPChangeEmail = async (
  uid?: string,
  otp?: string,
  refCode?: string
) => {
  const path = `${`/member/email/verifyotp`}`;
  const {
    data: { data }
  } = await axios.post<HtiResponse<LoginResponse>>(path, {
    uid: uid,
    referenceCode: refCode,
    otp: otp
  });
  return data;
};

export const requestPassword = async (email: string) => {
  const {
    data: { data }
  } = await axios.post<HtiResponse<OtpPassword>>(REQUEST_FORGOTPASSWORD_URL, {
    userID: email
  });
  return data;
};

export const requestChangeEmail = async (email: string) => {
  const {
    data: { data }
  } = await axios.post<HtiResponse<OtpPassword>>(CHANGE_EMAIL_URL, {
    email: email
  });
  return data;
};

export const requestChangePassword = async () => {
  const {
    data: { data }
  } = await axios.post<HtiResponse<OtpPassword>>(REQUEST_CHANGEPASSWORD_URL);
  return data;
};

export const getSignInSsoUrl = async (companyPrefix: string) => {
  const { data } = await axios.get<HtiResponse<SsoUrl>>(
    `${SIGNIN_SSO}/${companyPrefix}`
  );
  return data;
};

export const signinWithSso = async (payload?: SignInSsoBody) => {
  const { data } = await axios.post<HtiResponse<SignInSsoResponse>>(
    SIGNIN_SSO,
    payload
  );
  return data;
};

export function logout() {
  return axios.patch(LOGOUT_URL);
}

export const deleteClientFromMember = async (
  password: string,
  clientUID: string
) => {
  try {
    const { data } = await axios.delete<HtiResponse>(`${DELETE_CLIENT_URL}`, {
      data: { password, clientUID }
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTokenByHash = async (code: string, ref: string) => {
  try {
    const { data } = await axios.post<HtiResponse<getTokenByCodeResponse>>(
      `/signin/validate/code`,
      {
        code,
        ref
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
